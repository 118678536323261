import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, toast, ToastContainer } from 'mdbreact'
import { Zoom } from 'react-toastify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { Link } from 'gatsby'

const validationSchema = Yup.object().shape({
  companyname: Yup.string()
    .min(2, 'Name must be 2 characters minimum')
    .required('Company name is required'),
  accountnumber: Yup.string()
    .min(2, 'Account must be 2 characters minimum')
    .required('Account number is required'),
  streetaddress: Yup.string()
    .min(2, 'Address must be 2 characters minimum')
    .required('Street address is required'),
  city: Yup.string()
    .min(2, 'City must be 2 characters minimum')
    .required('City is required'),       
  state: Yup.string()
    .min(2, 'State must be 2 characters minimum')
    .required('State / Province is required'),  
  zip: Yup.string()
    .min(2, 'Zip must be 2 characters minimum')
    .required('Postal / Zip code is required'),  
  country: Yup.string()
    .min(2, 'Country must be 2 characters minimum')
    .required('Country is required'),
  primaryname: Yup.string()
    .min(2, 'Name must be 2 characters minimum')
    .required('Name is required'),
  primarytitle: Yup.string()
    .min(2, 'Title must be 2 characters minimum')
    .required('Job title is required'),  
  primaryphone: Yup.string()
    .min(2, 'Phone must be 2 characters minimum')
    .required('Phone number is required'),     
  primaryemail: Yup.string()
    .email('Invalid email address format')
    .required('Email is required'),
  technicalname: Yup.string()
    .min(2, 'Name must be 2 characters minimum')
    .required('Name is required'),
  technicaltitle: Yup.string()
    .min(2, 'Title must be 2 characters minimum')
    .required('Job title is required'),  
  technicalphone: Yup.string()
    .min(2, 'Phone must be 2 characters minimum')
    .required('Phone number is required'),     
  technicalemail: Yup.string()
    .email('Invalid email address format')
    .required('Email is required'), 
  managementname: Yup.string()
    .min(2, 'Name must be 2 characters minimum')
    .required('Name is required'),
  managementtitle: Yup.string()
    .min(2, 'Title must be 2 characters minimum')
    .required('Job title is required'),  
  managementphone: Yup.string()
    .min(2, 'Phone must be 2 characters minimum')
    .required('Phone number is required'),     
  managementemail: Yup.string()
    .email('Invalid email address format')
    .required('Email is required'), 
  billingname: Yup.string()
    .min(2, 'Name must be 2 characters minimum')
    .required('Name is required'),
  billingtitle: Yup.string()
    .min(2, 'Title must be 2 characters minimum')
    .required('Job title is required'),  
  billingphone: Yup.string()
    .min(2, 'Phone must be 2 characters minimum')
    .required('Phone number is required'),     
  billingemail: Yup.string()
    .email('Invalid email address format')
    .required('Email is required'), 
  phenvironment: Yup.array().of(
    Yup.object().shape({
      hostname: Yup.string().required('Required'),
      license: Yup.string().required('Required'),
      version: Yup.string().required('Required'),
      os: Yup.string().required('Required'),
      hardware: Yup.string().required('Required'),
    })
  ),  
  axenvironment: Yup.array().of(
    Yup.object().shape({
      hostname: Yup.string().required('Required'),
      license: Yup.string().required('Required'),
      version: Yup.string().required('Required'),
      os: Yup.string().required('Required'),
      hardware: Yup.string().required('Required'),
    })
  ),  
  completedbyname: Yup.string()
    .min(2, 'Name must be 2 characters minimum')
    .required('Name is required'),
  completedbytitle: Yup.string()
    .min(2, 'Title must be 2 characters minimum')
    .required('Job title is required'),    
  completedbyemail: Yup.string()
    .email('Invalid email address format')
    .required('Email is required'),                                 

  // subscribe: Yup.bool().oneOf([true], 'Terms and conditions need to be accepted'),
})

const formUrl = 'https://script.google.com/macros/s/AKfycbxaBnjYxZ05m5Gd7JAv79GuLRd3CX22CvI2Hwml77vd9e02cKf6n5s03F8lQjGSdSIghQ/exec'

const messageConfirm = () => {
  return toast.info("Thank you for contacting us.  We'll be in touch shortly!", { closeButton: false, position: toast.POSITION.TOP_CENTER, transition: Zoom })
}

class ContactMain extends Component {
  constructor(props, ...args) {
    super(props, ...args)
    this.state = {
      callback: 'not fired',
      value: null,
      pageUrl: null, 
      load: false,
      expired: 'false',
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ 
        load: true, 
        pageUrl: location.href
      })
    })
  }

  handleChange = value => {
    this.setState({ value })
  }

  asyncScriptOnLoad = () => {
    this.setState({ callback: 'called!' })
  }

  render() {
    const { value, callback, load, expired } = this.state || {}

    return (
      <MDBContainer>
        <MDBRow className="justify-content-center">
          <MDBCol lg="9" md="12" className="md-0 mb-5">
            <Formik
              initialValues={{
                companyname: '',
                accountnumber: '',
                streetaddress: '',
                streetaddress2: '',
                city: '',
                state: '',
                zip: '',
                country: '',
                primaryname: '',
                primarytitle: '',
                primaryphone: '',
                primaryemail: '',
                technicalname: '',
                technicaltitle: '',
                technicalphone: '',
                technicalemail: '',
                managementname: '',
                managementtitle: '',
                managementphone: '',
                managementemail: '',
                billingname: '',
                billingtitle: '',
                billingphone: '',
                billingemail: '',
                phenvironment: [], 
                axenvironment: [], 
                completedbyname: '',
                completedbytitle: '',
                completedbyemail: '',                                               
                subscribe: false,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, {setSubmitting, setFieldTouched, resetForm}) => {
                /* alert('Form is validated! Submitting the form...') */
                let formData = {
                  PageUrl: this.state.pageUrl,
                  CompanyName: values.companyname,
                  AccountNumber: values.accountnumber,
                  StreetAddress: values.streetaddress,
                  StreetAddress2: values.streetaddress2,
                  City: values.city,
                  State: values.state,
                  Zip: values.zip,
                  Country: values.country,
                  PrimaryName: values.primaryname,
                  PrimaryTitle: values.primarytitle,
                  PrimaryPhone: values.primaryphone,
                  PrimaryEmail: values.primaryemail,
                  TechnicalName: values.technicalname,
                  TechnicalTitle: values.technicaltitle,
                  TechnicalPhone: values.technicalphone,
                  TechnicalEmail: values.technicalemail,
                  ManagementName: values.managementname,
                  ManagementTitle: values.managementtitle,
                  ManagementPhone: values.managementphone,
                  ManagementEmail: values.managementemail,
                  BillingName: values.billingname,
                  BillingTitle: values.billingtitle,
                  BillingPhone: values.billingphone,
                  BillingEmail: values.billingemail,
                  PowerHouseEnvironment: values.phenvironment,
                  AxiantEnvironment: values.axenvironment,
                  CompletedName: values.completedbyname,
                  CompletedTtle: values.completedbytitle,
                  CompletedByEmail: values.completedbyemail,                                  
                  Subscribe: values.subscribe,
                }
                try {
                  const response = axios({
                      method: 'get',
                      url: `${formUrl}?timestamp=&url=${encodeURIComponent(this.state.pageUrl)}&companyname=${encodeURIComponent(values.companyname)}&accountnumber=${encodeURIComponent(values.accountnumber)}&streetaddress=${encodeURIComponent(values.streetaddress)}&streetaddress2=${encodeURIComponent(values.streetaddress2)}&city=${encodeURIComponent(values.city)}&state=${encodeURIComponent(values.state)}&zip=${encodeURIComponent(values.zip)}&country=${encodeURIComponent(values.country)}&primaryname=${encodeURIComponent(values.primaryname)}&primarytitle=${encodeURIComponent(values.primarytitle)}&primaryphone=${encodeURIComponent(values.primaryphone)}&primaryemail=${encodeURIComponent(values.primaryemail)}&technicalname=${encodeURIComponent(values.technicalname)}&technicaltitle=${encodeURIComponent(values.technicaltitle)}&technicalphone=${encodeURIComponent(values.technicalphone)}&technicalemail=${encodeURIComponent(values.technicalemail)}&managementname=${encodeURIComponent(values.managementname)}&managementtitle=${encodeURIComponent(values.managementtitle)}&managementphone=${encodeURIComponent(values.managementphone)}&managementemail=${encodeURIComponent(values.managementemail)}&billingname=${encodeURIComponent(values.billingname)}&billingtitle=${encodeURIComponent(values.billingtitle)}&billingphone=${encodeURIComponent(values.billingphone)}&billingemail=${encodeURIComponent(values.billingemail)}&phenvironment=${encodeURIComponent(JSON.stringify(values.phenvironment))}&axenvironment=${encodeURIComponent(JSON.stringify(values.axenvironment))}&completedbyname=${encodeURIComponent(values.completedbyname)}&completedbytitle=${encodeURIComponent(values.completedbytitle)}&completedbyemail=${encodeURIComponent(values.completedbyemail)}&subscribe=${encodeURIComponent(values.subscribe)}`
                    })
                      //console.log(response)
                      this.setState({values: '', expired: 'true'})
                      resetForm()
                      messageConfirm()
                    } catch (e) {
                      console.log(`Axios request failed: ${e}`)
                    }
                  setSubmitting(false)
                }}
            >
              {({ touched, errors, values, setFieldValue, setFieldTouched, isSubmitting }) => {
                const addRowPH = () => {
                  setFieldValue('phenvironment', [
                    ...values.phenvironment,
                    {
                      hostname: '', 
                      license: '', 
                      version: '', 
                      os: '', 
                      hardware: ''
                    }
                  ])
                }

                const addRowAX = () => {
                  setFieldValue('axenvironment', [
                    ...values.axenvironment,
                    {
                      hostname: '', 
                      license: '', 
                      version: '', 
                      os: '', 
                      hardware: ''
                    }
                  ])
                }

                const handleChangePH = (index, field, value) => {
                  setFieldValue(`phenvironment.${index}.${field}`, value)
                  setFieldTouched(`phenvironment.${index}.${field}`, true, false)
                }
              
                const handleChangeAX = (index, field, value) => {
                  setFieldValue(`axenvironment.${index}.${field}`, value)
                  setFieldTouched(`axenvironment.${index}.${field}`, true, false)
                }

              return (
                <Form name="contact" method="post" action="" data-netlify="true" data-netlify-honeypot="bot-field" >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="powerhouse-crf" />

                  <section className="form-section">
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-5">Company information</h2>
                          <hr />
                          <label htmlFor="companyname"> Company name <span style={{ color: 'red' }}>*</span> </label>
                          <Field type="text" id="companyname "name="companyname" aria-label="companyname" value={values.companyname} placeholder="Your company name" className={`form-control ${ touched.companyname && errors.companyname ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="companyname" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="accountnumber"> Account number <span style={{ color: 'red' }}>*</span> </label>
                          <Field type="text" id="accountnumber" name="accountnumber" aria-label="accountnumber" value={values.accountnumber} placeholder="Your account number" className={`form-control ${ touched.accountnumber && errors.accountnumber ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="accountnumber" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </section>

                  <section className="form-section">
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-5">Address</h2>
                          <hr />
                          <label htmlFor="streetaddress"> Street address <span style={{ color: 'red' }}>*</span> </label>
                          <Field type="text" id="streetaddress "name="streetaddress" aria-label="streetaddress" value={values.streetaddress} placeholder="Address line 1" className={`form-control ${ touched.streetaddress && errors.streetaddress ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="streetaddress" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="streetaddress2"> Street address line 2 </label>
                          <Field type="text" id="streetaddress2" name="streetaddress2" aria-label="streetaddress2" value={values.streetaddress2} placeholder="Address line 2" className={`form-control ${ touched.streetaddress2 && errors.streetaddress2 ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="streetaddress2" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="city"> City <span style={{ color: 'red' }}>*</span> </label>
                          <Field type="text" id="city" name="city" aria-label="city" value={values.city} placeholder="City" className={`form-control ${ touched.city && errors.city ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="city" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="state"> State / Province <span style={{ color: 'red' }}>*</span> </label>
                          <Field type="text" id="state" name="state" aria-label="state" value={values.state} placeholder="State / Province" className={`form-control ${ touched.state && errors.state ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="state" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>  

                    <MDBRow>
                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="zip"> Postal / Zip code <span style={{ color: 'red' }}>*</span> </label>
                          <Field type="text" id="zip" name="zip" aria-label="zip" value={values.zip} placeholder="Postal / Zip code" className={`form-control ${ touched.zip && errors.zip ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="zip" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="country"> Country <span style={{ color: 'red' }}>*</span> </label>
                          <Field type="text" id="country" name="country" aria-label="country" value={values.country} placeholder="Country" className={`form-control ${ touched.country && errors.country ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="country" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>     
                  </section>                              

                  <section className="form-section">
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-5">Primary contact</h2>
                          <hr />
                          <label htmlFor="primaryname"> Full name <span style={{ color: 'red' }}>*</span> </label>
                          <Field type="text" id="primaryname "name="primaryname" aria-label="primaryname" value={values.primaryname} placeholder="Primary contact full name" className={`form-control ${ touched.primaryname && errors.primaryname ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="primaryname" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="primarytitle">  Title/position <span style={{ color: 'red' }}>*</span> </label>
                          <Field type="text" id="primarytitle" name="primarytitle" aria-label="primarytitle" value={values.primarytitle} placeholder="Primary contact title/position" className={`form-control ${ touched.primarytitle && errors.primarytitle ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="primarytitle" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="primaryemail"> Phone number <span style={{ color: 'red' }}>*</span> </label>
                          <Field type="text" id="primaryphone" name="primaryphone" aria-label="primaryphone" value={values.primaryphone} placeholder="Primary contact phone" className={`form-control ${ touched.primaryphone && errors.primaryphone ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="primaryphone" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="state"> Email <span style={{ color: 'red' }}>*</span> </label>
                          <Field type="primaryemail" id="primaryemail" name="primaryemail" aria-label="primaryemail" value={values.primaryemail} placeholder="Primary contact email" className={`form-control ${ touched.primaryemail && errors.primaryemail ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="primaryemail" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-5">Technical contact</h2>
                          <hr />
                          <label htmlFor="technicalname"> Full name <span style={{ color: 'red' }}>*</span> </label>
                          <Field type="text" id="technicalname "name="technicalname" aria-label="technicalname" value={values.technicalname} placeholder="Technical contact full name" className={`form-control ${ touched.technicalname && errors.technicalname ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="technicalname" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="technicaltitle">  Title/position <span style={{ color: 'red' }}>*</span> </label>
                          <Field type="text" id="technicaltitle" name="technicaltitle" aria-label="technicaltitle" value={values.technicaltitle} placeholder="Technical contact title/position" className={`form-control ${ touched.technicaltitle && errors.technicaltitle ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="technicaltitle" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="technicalphone"> Phone number <span style={{ color: 'red' }}>*</span> </label>
                          <Field type="text" id="technicalphone" name="technicalphone" aria-label="technicalphone" value={values.technicalphone} placeholder="Technical contact phone" className={`form-control ${ touched.technicalphone && errors.technicalphone ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="technicalphone" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="technicalemail"> Email <span style={{ color: 'red' }}>*</span> </label>
                          <Field type="technicalemail" id="technicalemail" name="technicalemail" aria-label="technicalemail" value={values.technicalemail} placeholder="Technical contact email" className={`form-control ${ touched.technicalemail && errors.technicalemail ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="technicalemail" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-5">Management contact</h2>
                          <hr />
                          <label htmlFor="managementname"> Full name <span style={{ color: 'red' }}>*</span> </label>
                          <Field type="text" id="managementname "name="managementname" aria-label="managementname" value={values.managementname} placeholder="Management contact full name" className={`form-control ${ touched.managementname && errors.managementname ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="managementname" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="managementtitle">  Title/position <span style={{ color: 'red' }}>*</span> </label>
                          <Field type="text" id="managementtitle" name="managementtitle" aria-label="managementtitle" value={values.managementtitle} placeholder="Management contact title/position" className={`form-control ${ touched.managementtitle && errors.managementtitle ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="managementtitle" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="managementphone"> Phone number <span style={{ color: 'red' }}>*</span> </label>
                          <Field type="text" id="managementphone" name="managementphone" aria-label="managementphone" value={values.managementphone} placeholder="Management contact phone" className={`form-control ${ touched.managementphone && errors.managementphone ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="managementphone" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="managementemail"> Email <span style={{ color: 'red' }}>*</span> </label>
                          <Field type="managementemail" id="managementemail" name="managementemail" aria-label="managementemail" value={values.managementemail} placeholder="Management contact email" className={`form-control ${ touched.managementemail && errors.managementemail ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="managementemail" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-5">Billing contact</h2>
                          <hr />
                          <label htmlFor="billingname"> Full name <span style={{ color: 'red' }}>*</span> </label>
                          <Field type="text" id="billingname "name="billingname" aria-label="billingname" value={values.billingname} placeholder="Billing contact full name" className={`form-control ${ touched.billingname && errors.billingname ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="billingname" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="billingtitle">  Title/position <span style={{ color: 'red' }}>*</span> </label>
                          <Field type="text" id="billingtitle" name="billingtitle" aria-label="billingtitle" value={values.billingtitle} placeholder="Billing contact title/position" className={`form-control ${ touched.billingtitle && errors.billingtitle ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="billingtitle" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="billingphone"> Phone number <span style={{ color: 'red' }}>*</span> </label>
                          <Field type="text" id="billingphone" name="billingphone" aria-label="billingphone" value={values.billingphone} placeholder="Billing contact phone" className={`form-control ${ touched.billingphone && errors.billingphone ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="billingphone" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="billingemail"> Email <span style={{ color: 'red' }}>*</span> </label>
                          <Field type="billingemail" id="billingemail" name="billingemail" aria-label="billingemail" value={values.billingemail} placeholder="Billing contact email" className={`form-control ${ touched.billingemail && errors.billingemail ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="billingemail" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </section>

                  <section className="form-section">
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-5">PowerHouse® environment information</h2>
                          <hr />
                        </div> 

                        <div className="mb-2 grey-text font-w-400 text-medium">
                          Please complete the following fields for all PowerHouse® installations. Specify all 
                          Hostname(s) and its related PowerHouse® license, version and build, operating system, 
                          and hardware model and serial.
                        </div>

                        <ul className="grey-text font-w-400 text-medium-small">
                          <li className="mb-2">Hostname: displayed using 'hostname' command on Windows, UNIX and Linux. For OpenVMS, 
                            using 'SHOW NETWORK' will display the TCIP/IP node name which is the hostname. 
                            Hostname and Hostybyname can also be displayed using the current 'powerhouse_display_PLATFORM_v2' 
                            program for a specific platform.</li>
                          <li className="mb-3">PowerHouse® Version & Build: displayed using any of these commands - 'pdl version' , 
                            'quick version' , 'quiz version' , 'qtp version'.</li>
                        </ul>

                        <label htmlFor="phenvironment"> <span style={{ color: 'red' }}>*</span> </label>

                        <div className="form-grid">
                          <div className="form-grid-header">
                            <label className="text-small text-center" style={{width: "16%"}}>Hostname</label>
                            <label className="text-small text-center" style={{width: "21%"}}>PowerHouse<sup>&reg;</sup> <br />license</label>
                            <label className="text-small text-center" style={{width: "21%"}}>PowerHouse<sup>&reg;</sup> <br />version &amp; build</label>
                            <label className="text-small text-center" style={{width: "21%"}}>Operating <br />system</label>
                            <label className="text-small text-center" style={{width: "21%"}}>Hardware <br />model &amp; serial</label>
                          </div>

                          {values.phenvironment.length < 1 ?
                            (<div className="form-grid-prompt text-center text-large font-w-700"><span style={{color: "#0d5883"}}>*** Add rows and complete the fields ***</span></div>)
                            : 
                            (<>
                              {values.phenvironment.map((row, index) => (
                                <div key={index} className="form-grid-row">
                                  <Field id={`phenvironment.${index}.hostname`} name={`phenvironment.${index}.hostname`} style={{width: "16%"}} onChange={(e) => handleChangePH(index, 'hostname', e.target.value)} className={`form-control form-control-grid ${ touched.phenvironment && touched.phenvironment[index] && touched.phenvironment[index].hostname && errors.phenvironment && errors.phenvironment[index] && errors.phenvironment[index].hostname ? 'is-invalid' : '' }`}/>
                                  <Field id={`phenvironment.${index}.license`} name={`phenvironment.${index}.license`} type={`phenvironment.${index}.license`} as="select" placeholder="Please select an option" style={{width: "21%"}} onChange={(e) => handleChangePH(index, 'license', e.target.value)}  className={`form-control form-control-grid ${ touched.phenvironment && touched.phenvironment[index] && touched.phenvironment[index].license && errors.phenvironment && errors.phenvironment[index] && errors.phenvironment[index].license ? 'is-invalid' : '' }`}>
                                    <option value="" label="Please select a value">
                                      {' '}
                                    </option>
                                    <option value="Development" label="Development">
                                      Development
                                    </option>
                                    <option value="Runtime w/ reporting" label="Runtime w/ reporting">
                                      Runtime w/ reporting
                                    </option>
                                    <option value="Runtime (only)" label="Runtime (only)">
                                      Runtime (only)
                                    </option>
                                    <option value="Reporting (only)" label="Reporting (only)">
                                      Reporting (only)
                                    </option>
                                    <option value="Thin-client" label="Thin-client">
                                      Thin-client
                                    </option>
                                    <option value="PowerHouse® Web" label="PowerHouse® Web">
                                      PowerHouse® Web
                                    </option>
                                  </Field>
                                  <Field id={`phenvironment.${index}.version`} name={`phenvironment.${index}.version`} style={{width: "21%"}} onChange={(e) => handleChangePH(index, 'version', e.target.value)} className={`form-control form-control-grid ${ touched.phenvironment && touched.phenvironment[index] && touched.phenvironment[index].version && errors.phenvironment && errors.phenvironment[index] && errors.phenvironment[index].version ? 'is-invalid' : '' }`}/>
                                  <Field id={`phenvironment.${index}.os`} name={`phenvironment.${index}.os`} type={`phenvironment.${index}.os`} as="select" placeholder="Please select an option" style={{width: "21%"}} onChange={(e) => handleChangePH(index, 'os', e.target.value)} className={`form-control form-control-grid ${ touched.phenvironment && touched.phenvironment[index] && touched.phenvironment[index].os && errors.phenvironment && errors.phenvironment[index] && errors.phenvironment[index].os ? 'is-invalid' : '' }`}>
                                    <option value="" label="Please select a value">
                                      {' '}
                                    </option>
                                    <option value="AIX Power" label="AIX Power">
                                      AIX Power
                                    </option>
                                    <option value="HP-UX HP-9000 (PA-RISC)" label="HP-UX HP-9000 (PA-RISC)">
                                      HP-UX HP-9000 (PA-RISC)
                                    </option>
                                    <option value="HP-UX Integrity (IA64)" label="HP-UX Integrity (IA64)">
                                      HP-UX Integrity (IA64)
                                    </option>
                                    <option value="Linux x86-x64" label="Linux x86-x64">
                                      Linux x86-x64
                                    </option>
                                    <option value="OpenVMS Integrity (IA64)" label="OpenVMS Integrity (IA64)">
                                      OpenVMS Integrity (IA64)
                                    </option>
                                    <option value="OpenVMS Alpha" label="OpenVMS Alpha">
                                      OpenVMS Alpha
                                    </option>
                                    <option value="Solaris (SPARC)" label="Solaris (SPARC)">
                                      Solaris (SPARC)
                                    </option>
                                    <option value="Windows x86-x64" label="Windows x86-x64">
                                      Windows x86-x64
                                    </option>
                                  </Field>
                                  <Field id={`phenvironment.${index}.hardware`} name={`phenvironment.${index}.hardware`} style={{width: "21%"}} onChange={(e) => handleChangePH(index, 'hardware', e.target.value)} className={`form-control form-control-grid ${ touched.phenvironment && touched.phenvironment[index] && touched.phenvironment[index].hardware && errors.phenvironment && errors.phenvironment[index] && errors.phenvironment[index].hardware ? 'is-invalid' : '' }`}/>
                                </div>
                              ))}
                            </>)
                          }
                          <div className="form-grid-btn">
                            <button type="button" className="mt-4 btn btn-medium btn-unicom" onClick={addRowPH}>ADD ROW</button>
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </section>

                  <section className="form-section">
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-5">Axiant® environment information</h2>
                          <hr />
                        </div> 

                        <div className="mb-2 grey-text font-w-400 text-medium">
                          Please complete the following fields for all Axiant® installations. Specify all Hostname(s) 
                          and its related Axiant® license, version, operating system, and hardware model and serial.
                        </div>

                        <ul className="grey-text font-w-400 text-medium-small">
                          <li className="mb-2">Hostname: displayed using 'hostname' command on Windows.</li>
                          <li className="mb-3">Axiant® Version; go to Control Panel &gt; Programs &gt; Programs 
                            and Features. Locate the UNICOM Axiant 4GL program and view Version column.</li>
                        </ul>

                        <label htmlFor="axenvironment"> <span style={{ color: 'red' }}>*</span> </label>

                        <div className="form-grid">
                          <div className="form-grid-header">
                            <label className="text-small text-center" style={{width: "16%"}}>Hostname</label>
                            <label className="text-small text-center" style={{width: "21%"}}>Axiant<sup>&reg;</sup> <br />license</label>
                            <label className="text-small text-center" style={{width: "21%"}}>Axiant<sup>&reg;</sup> <br />version &amp; build</label>
                            <label className="text-small text-center" style={{width: "21%"}}>Operating <br />system</label>
                            <label className="text-small text-center" style={{width: "21%"}}>Hardware <br />model &amp; serial</label>
                          </div>

                          {values.axenvironment.length < 1 ?
                            (<div className="form-grid-prompt text-center text-large font-w-700"><span style={{color: "#0d5883"}}>*** Add rows and complete the fields ***</span></div>)
                            : 
                            (<>
                              {values.axenvironment.map((row, index) => (
                                <div key={index}>
                                  <Field id={`axenvironment.${index}.hostname`} name={`axenvironment.${index}.hostname`} style={{width: "16%"}} onChange={(e) => handleChangeAX(index, 'hostname', e.target.value)} className={`form-control form-control-grid ${ touched.axenvironment && touched.axenvironment[index] && touched.axenvironment[index].hostname && errors.axenvironment && errors.axenvironment[index] && errors.axenvironment[index].hostname ? 'is-invalid' : '' }`}/>
                                  <Field id={`axenvironment.${index}.license`} name={`axenvironment.${index}.license`} type={`axenvironment.${index}.license`} as="select" placeholder="Please select an option" style={{width: "21%"}} onChange={(e) => handleChangeAX(index, 'license', e.target.value)}  className={`form-control form-control-grid ${ touched.axenvironment && touched.axenvironment[index] && touched.axenvironment[index].license && errors.axenvironment && errors.axenvironment[index] && errors.axenvironment[index].license ? 'is-invalid' : '' }`}>
                                    <option value="" label="Please select a value">
                                      {' '}
                                    </option>
                                    <option value="Axiant Workbench" label="Axiant Workbench">
                                      Axiant Workbench
                                    </option>
                                    <option value="Axiant Client" label="Axiant Client">
                                      Axiant Client
                                    </option>
                                  </Field>
                                  <Field id={`axenvironment.${index}.version`} name={`axenvironment.${index}.version`} style={{width: "21%"}} onChange={(e) => handleChangeAX(index, 'version', e.target.value)} className={`form-control form-control-grid ${ touched.axenvironment && touched.axenvironment[index] && touched.axenvironment[index].version && errors.axenvironment && errors.axenvironment[index] && errors.axenvironment[index].version ? 'is-invalid' : '' }`}/>
                                  <Field id={`axenvironment.${index}.os`} name={`axenvironment.${index}.os`} type={`axenvironment.${index}.os`} as="select" placeholder="Please select an option" style={{width: "21%"}} onChange={(e) => handleChangeAX(index, 'os', e.target.value)} className={`form-control form-control-grid ${ touched.axenvironment && touched.axenvironment[index] && touched.axenvironment[index].os && errors.axenvironment && errors.axenvironment[index] && errors.axenvironment[index].os ? 'is-invalid' : '' }`}>
                                    <option value="" label="Please select a value">
                                      {' '}
                                    </option>
                                    <option value="Windows x86-x64" label="Windows x86-x64">
                                      Windows x86-x64
                                    </option>
                                  </Field>
                                  <Field id={`axenvironment.${index}.hardware`} name={`axenvironment.${index}.hardware`} style={{width: "21%"}} onChange={(e) => handleChangeAX(index, 'hardware', e.target.value)} className={`form-control form-control-grid ${ touched.axenvironment && touched.axenvironment[index] && touched.axenvironment[index].hardware && errors.axenvironment && errors.axenvironment[index] && errors.axenvironment[index].hardware ? 'is-invalid' : '' }`}/>
                                </div>
                              ))}
                            </>)
                        }
                          <div className="form-grid-btn pb-5">
                            <button type="button" className="mt-4 btn btn-medium btn-unicom" onClick={addRowAX}>Add Row</button>
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </section>

                  <section className="form-section">
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-5">Completed by</h2>
                          <hr />
                          <label htmlFor="completedbyname"> Full name <span style={{ color: 'red' }}>*</span> </label>
                          <Field type="text" id="completedbyname "name="completedbyname" aria-label="completedbyname" value={values.completedbyname} placeholder="Your full name" className={`form-control ${ touched.completedbyname && errors.completedbyname ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="completedbyname" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="completedbytitle">  Title/position <span style={{ color: 'red' }}>*</span> </label>
                          <Field type="text" id="completedbytitle" name="completedbytitle" aria-label="completedbytitle" value={values.completedbytitle} placeholder="Your title/position" className={`form-control ${ touched.completedbytitle && errors.completedbytitle ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="completedbytitle" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="completedbyemail"> Email <span style={{ color: 'red' }}>*</span> </label>
                          <Field type="completedbyemail" id="completedbyemail" name="completedbyemail" aria-label="completedbyemail" value={values.completedbyemail} placeholder="Your email" className={`form-control ${ touched.completedbyemail && errors.completedbyemail ? 'is-invalid' : '' }`} />
                          <ErrorMessage component="div" name="completedbyemail" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </section>

                  <hr />  

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mt-3 mb-4 grey-text font-w-400 text-small">
                        To receive occasional updates about UNICOM Systems Inc's
                        products, services, news and events, please tick the box
                        below. You can unsubscribe at any time.
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="form-group form-check mt-3 mb-4 grey-text font-w-400 text-small">
                        <Field
                          type="checkbox"
                          id="subscribe"
                          name="subscribe"
                          aria-label="subscribe"
                          className={`form-check-input ${
                            touched.subscribe && errors.subscribe
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <label htmlFor="subscribe">
                          Yes, I would like to receive communications from
                          UNICOM Systems, Inc.
                        </label>
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-400 text-small">
                        For details on how your data is stored and used, please
                        visit our{' '}
                        <Link to="/privacy-statement/" className="effect">
                          Privacy Statement
                        </Link>
                        .
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12" className="mb-4">
                      <div style={{ paddingTop: '1rem' }}>
                        <button
                          type="submit"
                          className="mt-4 btn btn-medium btn-unicom"
                          style={{ display: 'block' }}
                          disabled={ isSubmitting ? true : false }
                        >
                          {isSubmitting ? 'Please wait...' : 'Submit'}
                        </button>
                        <ToastContainer
                          transition={Zoom}
                          hideProgressBar={true}
                          newestOnTop={true}
                          autoClose={5000}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                </Form>
              )
              }}
            </Formik>
          </MDBCol>

     
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default ContactMain
